import {useState} from "react";
import {Tabs, Tab, Button, Card, Spinner} from "react-bootstrap";
import {errorHandler, getACorpus, getApiKey, getApiUrl, getEvidence, getSomeConcepts} from "./Data";

function Corpora() {
    const [loadingCorpora, setLoadingCorpora] = useState(false)
    const [corporaData, setCorporaData] = useState(null as any)

    const [loadingDoc, setLoadingDoc] = useState(false)
    const [docPayload, setDocPayload] = useState(null as any)
    const [docData, setDocData] = useState(null as any)

    function getCorpora() {
        setLoadingCorpora(true)
        setCorporaData(null)

        fetch(`${getApiUrl()}api/v1/corpora`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getApiKey()}`,
            },
        })
            .then(errorHandler)
            .then((data) => setCorporaData(data))
            .catch(window.alert)
            .finally(() => setLoadingCorpora(false))
    }

    function getDocument() {
        setLoadingDoc(true)
        setDocPayload(null)

        let corpusId: string

        // To get a document, we first need a corpus. Then we need a concept. Then we need evidence. From the evidence,
        // we can get a document.

        // First get a corpus id.
        getACorpus()
            .then((corpus: any) => {
                corpusId = corpus.id
                // Then get the top concepts in the first corpus.
                return getSomeConcepts(corpusId)
            })
            .then((concepts) => getEvidence(corpusId, [concepts[0]]))
            .then((evidence) => {
                // Then get the document for the evidence.
                const payload = {
                    corpus_ids: [evidence[0].instances[0].corpus_id],
                    id: evidence[0].instances[0].document_id,
                }
                setDocPayload(payload)
                return fetch(`${getApiUrl()}api/v1/corpora/document`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getApiKey()}`,
                    },
                    body: JSON.stringify(payload)
                })
            })
            .then(errorHandler)
            .then((data) => setDocData(data))
            .catch(window.alert)
            .finally(() => setLoadingDoc(false))
    }

    return (
        <div>
            <h2>Corpora Examples</h2>
            <Tabs
                defaultActiveKey="get-corpora"
                id="corpora-tabs"
                className="mb-3 mt-4"
            >
                <Tab eventKey="get-corpora" title="Get Available Corpora">
                    <p>
                        GET {getApiUrl()}api/v1/corpora
                    </p>
                    <p>
                        Read the <a target="cora_v1_api_docs" href="https://kb.ec.ai/docs/get-api-v1-corpora">docs</a>.
                    </p>
                    <Button variant="outline-primary" onClick={getCorpora} disabled={loadingCorpora}>Execute</Button>
                    {!loadingCorpora || (<div className="mt-4">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>)}
                    {corporaData === null || (
                        <Tabs
                            defaultActiveKey="results"
                            id="get-corpora-results"
                            className="mt-5"
                        >
                            <Tab eventKey="results" title="Results">
                                {corporaData!.corpora.map((corpus: any, i: number) => {
                                    return (
                                        <Card className="bg-light mt-2" style={{width: "50%"}} key={i}>
                                            <Card.Body>
                                                <div style={{display: "grid", gridTemplateColumns: "100px 1fr"}}>
                                                    <div><strong>Name:</strong></div>
                                                    <div className="ps-4">{corpus.name}</div>

                                                    <div><strong>ID:</strong></div>
                                                    <div className="ps-4">{corpus.id}</div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    );
                                })}
                            </Tab>
                            <Tab eventKey="raw" title="Raw Results">
                                <pre className="p-4"><code>{JSON.stringify(corporaData, null, 2)}</code></pre>
                            </Tab>
                        </Tabs>
                    )}
                </Tab>
                <Tab eventKey="get-doc" title="Get Document Details from a Corpus">
                    <p>
                        POST /api/v1/corpora/document
                    </p>
                    <p>
                        Read the <a target="cora_v1_api_docs" href="https://kb.ec.ai/docs/post-api-v1-corpora-document">docs</a>.
                    </p>
                    <Button variant="outline-primary" onClick={getDocument} disabled={loadingDoc}>Execute</Button>
                    {!loadingDoc || (<div className="mt-4">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>)}
                    {docData === null || (
                        <Tabs
                            defaultActiveKey="results"
                            id="get-document-results"
                            className="mt-5"
                        >
                            <Tab eventKey="results" title="Results">
                                <Card className="bg-light mt-2" style={{width: "80%"}}>
                                    <Card.Body>
                                        <div style={{display: "grid", gridTemplateColumns: "100px 1fr"}}>
                                            <div><strong>Title:</strong></div>
                                            <div className="ps-4" style={{overflowWrap: "anywhere"}}>{docData.document.title}</div>

                                            <div><strong>ID:</strong></div>
                                            <div className="ps-4" style={{overflowWrap: "anywhere"}}>{docData.document.id}</div>

                                            <div><strong>Corpus ID:</strong></div>
                                            <div className="ps-4" style={{overflowWrap: "anywhere"}}>{docData.document.corpus_id}</div>

                                            <div><strong>URL:</strong></div>
                                            <div className="ps-4" style={{overflowWrap: "anywhere"}}>{docData.document.url}</div>

                                            <div className="mt-5" style={{gridColumn: "1 / span 2", whiteSpace: "pre-wrap", overflowWrap: "anywhere"}}>{docData.document.text}</div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Tab>
                            <Tab eventKey="payload" title="Payload">
                                <pre className="p-4"><code>{JSON.stringify(docPayload, null, 2)}</code></pre>
                            </Tab>
                            <Tab eventKey="raw" title="Raw Results">
                                <pre className="p-4"><code>{JSON.stringify(docData, null, 2)}</code></pre>
                            </Tab>
                        </Tabs>
                    )}
                </Tab>
            </Tabs>
        </div>
    );
}

export default Corpora;