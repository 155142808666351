import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {getApiKey, getApiUrl} from "./Data";

function License() {
    const [apiUrl, setApiUrl] = useState(getApiUrl());
    const [apiKey, setApiKey] = useState(getApiKey());
    const [viewApiKey, setViewApiKey] = useState(false)

    useEffect(() => localStorage.setItem("apiUrl", apiUrl), [apiUrl]);
    useEffect(() => localStorage.setItem("apiKey", apiKey), [apiKey]);

    return (
        <div>
            <h2>API License Information</h2>
            <div className="mb-3 mt-4">
                <p>
                    Contact your EC representative to receive an API URL and API key and for access to the documentation
                    links.
                </p>
                <Form style={{width: "50%"}}>
                    <Form.Group className="mb-3" controlId="apiUrl">
                        <Form.Label>API URL</Form.Label>
                        <Form.Control type="text" value={apiUrl} onChange={(e) => setApiUrl(e.currentTarget.value)}/>
                        <Form.Text className="text-muted">
                            The API URL is https://api.cora.ec.ai/ unless told otherwise.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="apiKey">
                        <Form.Label>API Key</Form.Label>
                        <Form.Control type={viewApiKey ? "text" : "password"} value={apiKey} onChange={(e) => setApiKey(e.currentTarget.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="viewApiKey">
                        <Form.Check type="checkbox" label="View API Key" onChange={(e) => setViewApiKey(e.currentTarget.checked)} />
                    </Form.Group>
                </Form>
            </div>
        </div>
    );
}

export default License;