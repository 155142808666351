import {useState} from "react";
import {Tabs, Tab, Button, Card, Spinner} from "react-bootstrap";
import {errorHandler, getACorpus, getApiKey, getApiUrl, getEvidence, getSomeConcepts} from "./Data";

function Suggest() {
    const [loadingSuggestion, setLoadingSuggestion] = useState(false)
    const [suggestionPayload, setSuggestionPayload] = useState(null as any)
    const [suggestionData, setSuggestionData] = useState(null as any)

    function getSuggestion() {
        setLoadingSuggestion(true)
        setSuggestionPayload(null)
        setSuggestionData(null)

        // To get a suggestion, we first need a corpus. Then we need a concept. Then we need evidence. From the evidence,
        // we can get a suggestion.

        let corpusId: string

        getACorpus()
            .then((corpus: any) => {
                corpusId = corpus.id
                // Then get the top concepts in the first corpus.
                return getSomeConcepts(corpusId)
            })
            .then((concepts) => getEvidence(corpusId, [concepts[0]]))
            .then((evidence) => {
                // Then get the suggestion for the evidence.
                const payload = {
                    corpus_ids: [evidence[0].instances[0].corpus_id],
                    finding: evidence[0].text,
                    evidence: evidence[0].text,
                    url: "",
                    source_document_title: "",
                }
                setSuggestionPayload(payload)
                return fetch(`${getApiUrl()}api/v1/suggest/evidence`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getApiKey()}`,
                    },
                    body: JSON.stringify(payload)
                })
            })
            .then(errorHandler)
            .then((data) => setSuggestionData(data))
            .catch(window.alert)
            .finally(() => setLoadingSuggestion(false))
    }

    return (
        <div>
            <h2>Suggest Examples</h2>
            <Tabs
                defaultActiveKey="get-suggestion"
                id="suggestion-tabs"
                className="mb-3 mt-4"
            >
                <Tab eventKey="get-suggestion" title="Get Additional Evidence Suggestions">
                    <p>
                        POST /api/v1/suggest/evidence
                    </p>
                    <p>
                        Read the <a target="cora_v1_api_docs"
                                    href="https://kb.ec.ai/docs/post-api-v1-suggest-evidence">docs</a>.
                    </p>
                    <Button variant="outline-primary" onClick={getSuggestion}
                            disabled={loadingSuggestion}>Execute</Button>
                    {!loadingSuggestion || (<div className="mt-4">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>)}
                    {suggestionData === null || (
                        <Tabs
                            defaultActiveKey="results"
                            id="get-suggestion-results"
                            className="mt-5"
                        >
                            <Tab eventKey="results" title="Results">
                                {suggestionData!.result.map((item: any, i: number) => {
                                    return (
                                        <Card className="bg-light mt-2" style={{width: "50%"}} key={i}>
                                            <Card.Body>
                                                <div style={{display: "grid", gridTemplateColumns: "130px 1fr"}}>
                                                    <div><strong>Text:</strong></div>
                                                    <div className="ps-4"
                                                         style={{overflowWrap: "anywhere"}}>{item.text}</div>

                                                    <div><strong>ID:</strong></div>
                                                    <div className="ps-4"
                                                         style={{overflowWrap: "anywhere"}}>{item.id}</div>

                                                    <div><strong>Type:</strong></div>
                                                    <div className="ps-4"
                                                         style={{overflowWrap: "anywhere"}}>{item.evidence_type}</div>

                                                    {!item.context || (
                                                        <>
                                                            <div><strong>Context Before:</strong></div>
                                                            <div className="ps-4"
                                                                 style={{overflowWrap: "anywhere"}}>{item.context.before}</div>
                                                            <div><strong>Context After:</strong></div>
                                                            <div className="ps-4"
                                                                 style={{overflowWrap: "anywhere"}}>{item.context.after}</div>
                                                        </>
                                                    )}

                                                    {item.instances.map((instance: any, j: number) => {
                                                        return (
                                                            <>
                                                                <div key={"inst1." + j}><strong>Instance Title:</strong>
                                                                </div>
                                                                <div key={"inst2." + j} className="ps-4"
                                                                     style={{overflowWrap: "anywhere"}}>{instance.title}</div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    );
                                })}
                            </Tab>
                            <Tab eventKey="payload" title="Payload">
                                <pre className="p-4"><code>{JSON.stringify(suggestionPayload, null, 2)}</code></pre>
                            </Tab>
                            <Tab eventKey="raw" title="Raw Results">
                                <pre className="p-4"><code>{JSON.stringify(suggestionData, null, 2)}</code></pre>
                            </Tab>
                        </Tabs>
                    )}
                </Tab>
            </Tabs>
        </div>
    );
}

export default Suggest;