import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    Routes,
    Route,
    HashRouter,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <HashRouter>
          <Routes>
              <Route path="/" element={<App />}>
                  <Route path="license" element={<></>} />
                  <Route path="corpora" element={<></>} />
                  <Route path="search" element={<></>} />
                  <Route path="suggest" element={<></>} />
                  <Route path="mini-search-app" element={<></>} />
              </Route>
          </Routes>
      </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
