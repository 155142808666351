import {Accordion, Badge, Button, Card, Col, Collapse, Form, Modal, Row, Spinner} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {errorHandler, getApiKey, getApiUrl} from "./Data";
import _ from "lodash";
import "bootstrap-icons/font/bootstrap-icons.css";

/**
 * Render the app.
 */
function MiniSearchApp() {
    const [corpus, setCorpus] = useState(null as any);

    return (
        <>
            {corpus !== null || (<CorpusInput onSelect={setCorpus}/>)}
            {corpus !== null && (<SearchPane corpusId={corpus.id}/>)}
        </>
    );
}

/**
 * An interface that holds the corpus filter information as well as evidence associated with it.
 */
interface Search {
    corpusId: string,
    context: any[],
    subjects: any[],
    relations: any[],
    objects: any[],
    secondaryObjects: any[],
    qualifiers: any[],
    evidence: any[],
    request: any, // The evidence request which is needed to provide feedback.
}

/**
 * Convert a concept cluster as returned by the API into a form that is usable in a corpus filter.
 */
function toConceptClusters(concepts: any[], argumentName?: string) {
    return concepts.map((concept: any) => {
        const {type_names, ...queryConcept} = concept
        return {argument_name: argumentName, ...queryConcept}
    })
}

/**
 * Convert an instance conforming to the Search interface into a corpus filter.
 */
function searchAsCorpusFilter(search: Partial<Search>, fieldSearch?: string): any {
    return {
        "corpus_ids": [search.corpusId],
        "context": toConceptClusters(search.context!),
        "concept_filter": {
            "filter": _.concat(
                fieldSearch === "subjects" ? [] : toConceptClusters(search.subjects!, "subject"),
                fieldSearch === "objects" ? [] : toConceptClusters(search.objects!, "object"),
                fieldSearch === "secondaryObjects" ? [] : toConceptClusters(search.secondaryObjects!, "secondary object")
            )
        },
        "relation_filter": {
            "filter": fieldSearch === "relations" ? [] : search.relations!
        },
        "required_arguments_filter": {
            "filter": search.qualifiers!.map(item => item.id)
        }
    }
}

/**
 * For styling evidence spans.
 */
const ROLE_CLASSES = {
    relation: "text-bg-primary",
    ARG0: "text-bg-success",
    ARG1: "text-bg-info",
    ARG2: "text-bg-warning",
} as Record<string, string>

/**
 * The widget used to get and select a corpus for the app.
 */
function CorpusInput({onSelect}: any) {
    const [loadingCorpora, setLoadingCorpora] = useState(false)
    const [corpora, setCorpora] = useState(null as any)

    function getCorpora() {
        setLoadingCorpora(true)
        setCorpora(null)

        fetch(`${getApiUrl()}api/v1/corpora`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getApiKey()}`,
            },
        })
            .then(errorHandler)
            .then((data) => setCorpora(data.corpora))
            .catch(window.alert)
            .finally(() => setLoadingCorpora(false))
    }

    return (<>
        {corpora == null && (<>
            <p>
                This applet lets you build a query and then execute it to find evidence in a corpus. First, push the
                button to load the available corpora.
            </p>
            <Button onClick={getCorpora} disabled={loadingCorpora}>Get Corpora</Button>
            {!loadingCorpora || (<div className="mt-4">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>)}
        </>)}
        {corpora != null && (<>
            <Form.Group className="mb-3" controlId="corpus">
                <Form.Label>Corpus</Form.Label>
                <Form.Select disabled={loadingCorpora}
                             onChange={evt => onSelect(corpora.find((data: any) => data.id === evt.target.value))}>
                    <option>Select a corpus</option>
                    {corpora && corpora.map((corpus: any) => <option key={corpus.id}
                                                                     value={corpus.id}>{corpus.name}</option>)}
                </Form.Select>
            </Form.Group>
        </>)}
    </>);
}

/**
 * The search widget that allows the user to build a query. It allows for the selection of a context, subject, relation,
 * object and qualifiers. It allows for the query to be executed against the corpus.
 */
function SearchPane({corpusId}: any) {
    const [context, setContext] = useState([] as any[]);
    const [subjects, setSubjects] = useState([] as any[]);
    const [relations, setRelations] = useState([] as any[]);
    const [objects, setObjects] = useState([] as any[]);
    const [secondaryObjects, setSecondaryObjects] = useState([] as any[]);
    const [qualifiers, setQualifiers] = useState(null as null | any[]);
    const [selectedQualifiers, setSelectedQualifiers] = useState([] as any[]);
    const [loadingEvidence, setLoadingEvidence] = useState(false)
    const [searches, setSearches] = useState([] as Search[])

    function findEvidence() {
        const request = {
            "count": 5,
            "evidence_type": "Mentioned",
            "corpus_filter": searchAsCorpusFilter(getSearch()),
        }
        const search = {...getSearch(), request} as Partial<Search>
        setLoadingEvidence(true)
        fetch(`${getApiUrl()}api/v1/search/evidence`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getApiKey()}`,
            },
            body: JSON.stringify(request)
        })
            .then(errorHandler)
            .then((data) => {
                search.evidence = data.evidence
                setSearches(_.concat([search as Search], searches))
            })
            .catch(window.alert)
            .finally(() => setLoadingEvidence(false))
    }

    useEffect(() => {
        if (qualifiers === null) {
            fetch(`${getApiUrl()}api/v1/search/arguments`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getApiKey()}`,
                },
                body: JSON.stringify({
                    "corpus_filter": {
                        "corpus_ids": [corpusId],
                    }
                })
            })
                .then(errorHandler)
                .then((data) => setQualifiers(data.argument_names.filter((arg: any) => arg.type === "MODIFIER")))
                .catch(window.alert)
        }
    }, [qualifiers])

    function setSearch(search: Search) {
        setContext(search.context)
        setSubjects(search.subjects)
        setRelations(search.relations)
        setObjects(search.objects)
        setSecondaryObjects(search.secondaryObjects)
        setSelectedQualifiers(search.qualifiers)
    }

    function getSearch(): Partial<Search> {
        return {corpusId, context, subjects, objects, secondaryObjects, relations, qualifiers: selectedQualifiers}
    }

    function changeQualifiers(qualifier: any, selected: boolean) {
        let newSelectedQualifiers = selectedQualifiers.filter(item => item.id !== qualifier.id)
        if (selected) {
            newSelectedQualifiers = _.concat(newSelectedQualifiers, [qualifier])
        }
        setSelectedQualifiers(newSelectedQualifiers)
    }

    return (
        <div className="mb-2" style={{display: "grid", gridTemplateColumns: "40% minmax(0, 1fr)", columnGap: "2rem"}}>
            <div style={{alignSelf: "start"}}>
                <Card>
                    <Card.Body>
                        <ConceptInput name="Context" search={getSearch()} argumentName="context"
                                      onChange={setContext}/>
                        <ConceptInput name="Subject" search={getSearch()} argumentName="subjects"
                                      onChange={setSubjects}/>
                        <RelationshipInput search={getSearch()} onChange={setRelations}/>
                        <ConceptInput name="Object" search={getSearch()} argumentName="objects" onChange={setObjects}/>
                        <ConceptInput name="Second Obj" search={getSearch()} argumentName="secondaryObjects" onChange={setSecondaryObjects}/>
                        {qualifiers !== null && qualifiers.map((qualifier: any) => <QualifierInput key={qualifier.id}
                                                                                                   search={getSearch()}
                                                                                                   qualifier={qualifier}
                                                                                                   onChange={changeQualifiers}/>)}
                        <Button className="mt-4" onClick={findEvidence} disabled={loadingEvidence}>Find
                            Evidence</Button>
                        {!loadingEvidence || (<div className="mt-4">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>)}
                    </Card.Body>
                </Card>
                <Card className="mt-2">
                    <Card.Body>
                        <p>
                            Click the magnifying glass next to the context, subject, relation and object fields to
                            search the corpus for values.
                        </p>
                        <p>
                            The search is contextual so if you have a subject selected and then search for
                            relations with no prefix then the top relations for that subject will be shown.
                        </p>
                        <p>
                            Click "Find Evidence" to query the corpus for evidence. Each result will show up to the
                            right with a summary of the query and the evidence. Clicking a query summary will populate
                            the fields above.
                        </p>
                        <p>
                            Evidence Annotations:
                            <div className="ms-2"><b>Highlight</b></div>
                            <div className={`ms-2 ${ROLE_CLASSES["ARG0"]}`}>Subject</div>
                            <div className={`ms-2 ${ROLE_CLASSES["relation"]}`}>Relation</div>
                            <div className={`ms-2 ${ROLE_CLASSES["ARG1"]}`}>Object</div>
                            <div className={`ms-2 ${ROLE_CLASSES["ARG2"]}`}>Secondary Object</div>
                            <div className="ms-2 text-decoration-underline">Other Argument</div>
                        </p>
                    </Card.Body>
                </Card>
            </div>
            <div>
                <Accordion defaultActiveKey="search0">
                    {searches.map((search: Search, i: number) => (
                        <SearchResult key={`search${i}`} search={search} eventKey={`search${i}`} onClick={setSearch}/>
                    ))}
                </Accordion>
            </div>
        </div>
    );
}

/**
 * A widget that allows a concept to be selected.
 */
function ConceptInput({
                          name,
                          onChange,
                          search,
                          argumentName
                      }: any & { search: Partial<Search>, argumentName: "subjects" | "objects" | "secondaryObjects" }) {
    const [concepts, setConcepts] = useState(search[argumentName])
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchText, setSearchText] = useState("")
    const [searchingConcepts, setSearchingConcepts] = useState(false)
    const [searchConcepts, setSearchConcepts] = useState([])

    function removeConcept(toRemove: any) {
        setConcepts(concepts.filter((concept: any) => !_.isEqual(concept, toRemove)))
    }

    function addConcept(toAdd: any) {
        setSearchConcepts(searchConcepts.filter((concept: any) => !_.isEqual(concept, toAdd)))
        setConcepts(_.concat(concepts.filter((concept: any) => !_.isEqual(concept, toAdd)), [toAdd]))
    }

    useEffect(() => onChange(concepts), [concepts])

    useEffect(() => setConcepts(search[argumentName]), [search[argumentName]])

    function doSearch(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault()
        setSearchingConcepts(true)
        setSearchConcepts([])
        fetch(`${getApiUrl()}api/v1/search/concepts`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getApiKey()}`,
            },
            body: JSON.stringify({
                "corpus_filter": searchAsCorpusFilter(search, argumentName),
                "clustering_mode": "MODERATE",
                "count": 10,
                "prefix": searchText,
            })
        })
            .then(errorHandler)
            .then((data) => setSearchConcepts(data.concepts))
            .catch(window.alert)
            .finally(() => setSearchingConcepts(false))
    }

    return (<>
        <div className="mb-2"
             style={{display: "grid", gridTemplateColumns: "100px minmax(0, 1fr) 20px", alignItems: "baseline"}}>
            <div><strong>{name}:</strong></div>
            <div className="ms-2">
                {concepts.length !== 0 || (<span className="mb-2" style={{display: "inline-block"}}>-</span>)}
                {concepts.length !== 0 && concepts.map((item: any) => (
                    <Entity key={item.ids.join(",")} className="bg-primary mb-2 me-2" text={item.name} children={(
                        <i className="ms-1 bi bi-x-circle" onClick={() => removeConcept(item)}/>)}/>
                ))}
            </div>
            <i className="ms-1 bi bi-search" style={{cursor: "pointer"}} onClick={() => setSearchOpen(!searchOpen)}/>
            <Collapse in={searchOpen}>
                <div style={{gridColumnStart: 2, gridColumnEnd: 3}}>
                    <Form>
                        <Form.Group as={Row} className="g-2" controlId="concept">
                            <Form.Label column sm={2}>Prefix</Form.Label>
                            <Col sm={6}>
                                <Form.Control type="text" value={searchText}
                                              onChange={event => setSearchText(event.currentTarget.value)}/>
                            </Col>
                            <Col sm={4}>
                                <Button type="submit" className="me-2" disabled={searchingConcepts}
                                        onClick={doSearch}>Search</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                    {!searchingConcepts || (<div className="mt-4">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>)}
                    <div className="mb-4">
                        {searchConcepts.map((concept: any) => (
                            <Entity className="bg-secondary mt-2 me-2" key={concept.ids.join(",")} text={concept.name}
                                    children={(
                                        <i className="ms-1 bi bi-plus-circle"
                                           onClick={() => addConcept(concept)}/>)}/>
                        ))}
                    </div>
                </div>
            </Collapse>
        </div>
    </>);
}

/**
 * A widget that allows a relation to be selected.
 */
function RelationshipInput({search, onChange}: any & { search: Partial<Search> }) {
    const [relations, setRelations] = useState(search.relations)
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchText, setSearchText] = useState("")
    const [searchingRelations, setSearchingRelations] = useState(false)
    const [searchRelations, setSearchRelations] = useState([])

    function removeRelation(toRemove: any) {
        setRelations(relations.filter((relation: any) => !_.isEqual(relation, toRemove)))
    }

    function addRelation(toAdd: any) {
        setSearchRelations(searchRelations.filter((relation: any) => !_.isEqual(relation, toAdd)))
        setRelations(_.concat(relations.filter((relation: any) => !_.isEqual(relation, toAdd)), [toAdd]))
    }

    useEffect(() => onChange(relations), [relations])

    useEffect(() => setRelations(search.relations), [search.relations])

    function doSearch() {
        setSearchingRelations(true)
        setSearchRelations([])
        fetch(`${getApiUrl()}api/v1/search/relations`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getApiKey()}`,
            },
            body: JSON.stringify({
                "corpus_filter": searchAsCorpusFilter(search, "relations"),
                "clustering_mode": "MODERATE",
                "count": 10,
                "prefix": searchText,
            })
        })
            .then(errorHandler)
            .then((data) => setSearchRelations(data.relations))
            .catch(window.alert)
            .finally(() => setSearchingRelations(false))
    }

    return (<>
        <div className="mb-2"
             style={{display: "grid", gridTemplateColumns: "100px minmax(0, 1fr) 20px", alignItems: "baseline"}}>
            <div><strong>Relation:</strong></div>
            <div className="ms-2">
                {relations.length !== 0 || (<span className="mb-2" style={{display: "inline-block"}}>-</span>)}
                {relations.length !== 0 && relations.map((item: any) => (
                    <Entity className="bg-primary mb-2 me-2" key={item.ids.join(",")} text={item.name} children={(
                        <i className="ms-1 bi bi-x-circle" onClick={() => removeRelation(item)}/>)}/>
                ))}
            </div>
            <i className="ms-1 bi bi-search" style={{cursor: "pointer"}} onClick={() => setSearchOpen(!searchOpen)}/>
            <Collapse in={searchOpen}>
                <div style={{gridColumnStart: 2, gridColumnEnd: 3}}>
                    <Form>
                        <Form.Group as={Row} className="g-2" controlId="relation">
                            <Form.Label column sm={2}>Prefix</Form.Label>
                            <Col sm={6}>
                                <Form.Control type="text" value={searchText}
                                              onChange={event => setSearchText(event.currentTarget.value)}/>
                            </Col>
                            <Col sm={4}>
                                <Button type="submit" className="me-2" disabled={searchingRelations}
                                        onClick={doSearch}>Search</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                    {!searchingRelations || (<div className="mt-4">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>)}
                    <div className="mb-4">
                        {searchRelations.map((relation: any) => (
                            <Entity className="bg-secondary mt-2 me-2" key={relation.ids.join(",")} text={relation.name}
                                    children={(
                                        <i className="ms-1 bi bi-plus-circle"
                                           onClick={() => addRelation(relation)}/>)}/>
                        ))}
                    </div>
                </div>
            </Collapse>
        </div>
    </>);
}

/**
 * A widget that allows a qualifier to be selected.
 */
function QualifierInput({qualifier, search, onChange}: any) {
    const [selected, setSelected] = useState(false)

    useEffect(() => onChange(qualifier, selected), [selected])

    useEffect(() => setSelected(search.qualifiers.some((item: any) => item.id === qualifier.id)), [search.qualifiers])

    return (<Button className="me-2" variant={selected ? "primary" : "outline-primary"}
                    onClick={() => setSelected(!selected)}>{qualifier.name}</Button>)
}

/**
 * A widget to show a search result. Search results are shown in a Bootstrap Accordion. The header is a summary of the
 * query. The body is a list of evidence. For each evidence item, there are buttons to show further evidence
 * suggestions, the document the evidence came from and to provide feedback on the evidence.
 */
function SearchResult({search, eventKey, onClick}: any) {
    const [feedback, setFeedback] = useState(Array(search.evidence.length) as (boolean | undefined)[])
    const [document, setDocument] = useState(null as any)
    const [suggestedEvidence, setSuggestedEvidence] = useState(null as any)

    function giveFeedback(index: number, positive: boolean) {
        const evidence = search.evidence[index]
        fetch(`${getApiUrl()}api/v1/feedback`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getApiKey()}`,
            },
            body: JSON.stringify({
                "feedback": [
                    {
                        "endpoint": "api/v1/search/evidence",
                        "generating_request": JSON.stringify(search.request),
                        "feedback_target": JSON.stringify(evidence),
                        "type": positive ? "POSITIVE" : "NEGATIVE",
                        "feature_identifier": "EVIDENCE RELEVANCE",
                        "comments": "this is a test entry"
                    },
                ],
            })
        })
            .then(errorHandler)
            .then((data) => {
                console.log("FB", data)
                const newFeedback = feedback.slice()
                newFeedback[index] = positive
                setFeedback(newFeedback)
            })
            .catch(window.alert)
    }

    function showDoc(index: number) {
        const evidence = search.evidence[index]
        fetch(`${getApiUrl()}api/v1/corpora/document`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getApiKey()}`,
            },
            body: JSON.stringify({
                corpus_ids: [evidence.instances[0].corpus_id],
                id: evidence.instances[0].document_id,
            })
        })
            .then(errorHandler)
            .then((data) => setDocument(data.document))
            .catch(window.alert)
    }

    function suggestEvidence(index: number) {
        const evidence = search.evidence[index]
        fetch(`${getApiUrl()}api/v1/suggest/evidence`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getApiKey()}`,
            },
            body: JSON.stringify({
                corpus_ids: [evidence.instances[0].corpus_id],
                finding: evidence.text,
                evidence: evidence.text,
                url: "",
                source_document_title: "",
            })
        })
            .then(errorHandler)
            .then((data) => setSuggestedEvidence(data.result))
            .catch(window.alert)
    }

    return (
        <>
            {document !== null && (
                <Modal show={true} onHide={() => setDocument(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{overflowWrap: "anywhere"}}>{document.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{whiteSpace: "pre-wrap", overflowWrap: "anywhere"}}>{document.text}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setDocument(null)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {suggestedEvidence !== null && (
                <Modal show={true} onHide={() => setSuggestedEvidence(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{overflowWrap: "anywhere"}}>Suggested Evidence</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {suggestedEvidence.length === 0 && "No suggestions were made."}
                        {suggestedEvidence.length !== 0 && suggestedEvidence.map((item: any) =>
                            <Evidence key={item.id} className="mb-2" item={item}/>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setSuggestedEvidence(null)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            <Accordion.Item eventKey={eventKey}>
                <Accordion.Header onClick={() => onClick(search)}>
                    <div>
                        {search.context.length === 0 || <div className="mb-2">
                            <b>Context:</b>&nbsp;
                            {search.context.map((item: any) => <Entity className="bg-primary me-2"
                                                                       key={item.ids.join(",")}
                                                                       text={item.name}/>)}
                        </div>}
                        {search.subjects.length === 0 || <div className="mb-2">
                            <b>Subject:</b>&nbsp;
                            {search.subjects.map((subject: any) => <Entity className="bg-primary me-2"
                                                                           key={subject.ids.join(",")}
                                                                           text={subject.name}/>)}
                        </div>}
                        {search.relations.length === 0 || <div className="mb-2">
                            <b>Relation:</b>&nbsp;
                            {search.relations.map((relation: any) => <Entity className="bg-primary me-2"
                                                                             key={relation.ids.join(",")}
                                                                             text={relation.name}/>)}
                        </div>}
                        {search.objects.length === 0 || <div className="mb-2">
                            <b>Objects:</b>&nbsp;
                            {search.objects.map((object: any) => <Entity className="bg-primary me-2"
                                                                         key={object.ids.join(",")}
                                                                         text={object.name}/>)}
                        </div>}
                        {search.secondaryObjects.length === 0 || <div className="mb-2">
                            <b>Secondary Objects:</b>&nbsp;
                            {search.secondaryObjects.map((secondaryObject: any) => <Entity className="bg-primary me-2"
                                                                         key={secondaryObject.ids.join(",")}
                                                                         text={secondaryObject.name}/>)}
                        </div>}
                        {search.qualifiers.length === 0 || <div>
                            <b>Qualifiers:</b>&nbsp;
                            {search.qualifiers.map((object: any) => <Entity className="bg-primary me-2"
                                                                            key={object.id}
                                                                            text={object.name}/>)}
                        </div>}
                        {search.subjects.length + search.relations.length + search.objects.length + search.secondaryObjects.length + search.qualifiers.length === 0 && "Empty Search"}
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="vstack gap-3">
                        {search.evidence.length === 0 && "No evidence found."}
                        {search.evidence.map((item: any, i: number) => <Evidence key={item.id} item={item} children={
                            <div className="mt-0">
                                <i className={`float-end ms-1 bi bi-hand-thumbs-down${feedback[i] !== undefined && !feedback[i] ? "-fill" : ""}`}
                                   style={{cursor: "pointer"}} onClick={() => giveFeedback(i, false)}/>
                                <i className={`float-end ms-1 bi bi-hand-thumbs-up${feedback[i] !== undefined && feedback[i] ? "-fill" : ""}`}
                                   style={{cursor: "pointer"}} onClick={() => giveFeedback(i, true)}/>
                                <i className="float-end ms-1 bi bi-filetype-doc" style={{cursor: "pointer"}}
                                   onClick={() => showDoc(i)}/>
                                <i className="float-end ms-1 bi bi-three-dots" style={{cursor: "pointer"}}
                                   onClick={() => suggestEvidence(i)}/>
                            </div>}/>
                        )
                        }
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </>
    );
}

/**
 * A widget to show evidence.
 */
function Evidence({item, children, className}: any) {
    // Order the argument spans and create entries for the gaps.
    const annotations = Array(item.text.length) as (number | undefined)[]
    item.argument_spans.forEach((argSpan: any, i: number) => {
        for (let offset = argSpan.span.start; offset < argSpan.span.end; offset++) {
            annotations[offset] = i
        }
    })
    let spans = [] as any[]
    let lastSpanStart = 0
    for (let i = 0; i < annotations.length; i++) {
        if (i > 0 && annotations[i] !== annotations[i - 1]) {
            spans.push({argSpan: annotations[i - 1], start: lastSpanStart, end: i})
            lastSpanStart = i
        }
    }
    spans.push({
        argSpan: annotations[annotations.length - 1],
        start: lastSpanStart,
        end: annotations.length
    })
    // Add in the highlights.
    const highlights = Array(item.text.length) as (true | undefined)[]
    item.highlight_spans.forEach((highlightSpan: any) => {
        for (let offset = highlightSpan.span.start; offset < highlightSpan.span.end; offset++) {
            highlights[offset] = true
        }
    })
    spans = spans.flatMap((span: any) => {
        const newSpans = []
        let lastSpanStart = span.start
        for (let i = span.start; i < span.end; i++) {
            if (i > 0 && highlights[i] !== highlights[i - 1]) {
                newSpans.push({argSpan: span.argSpan, start: lastSpanStart, end: i, highlight: highlights[i - 1]})
                lastSpanStart = i
            }
        }
        newSpans.push({
            argSpan: span.argSpan,
            start: lastSpanStart,
            end: span.end,
            highlight: highlights[span.end - 1]
        })
        return newSpans
    })

    return (
        <Card className={className}>
            <Card.Body>
                {item.context !== null && <span className="text-opacity-50">{item.context.before} </span>}
                {spans.map((span: any, i: number) => {
                    let className = ""
                    if (span.argSpan !== undefined) {
                        className = ROLE_CLASSES[item.argument_spans[span.argSpan].argument.id]
                        if (!className) {
                            className = "text-decoration-underline"
                        }
                    }
                    return (<span
                        className={className}
                        style={span.highlight ? {fontWeight: "bold"} : {}}
                        key={`span.${i}`}>{(item.text as string).substring(span.start, span.end)}</span>)
                })}
                {item.context !== null && <span className="text-opacity-50"> {item.context.after}</span>}
                {children}
            </Card.Body>
        </Card>
    )
}

/**
 * A widget to show an entity (a concept or a relation).
 */
function Entity({text, children, className}: any) {
    return (
        <Badge className={className} pill
               style={{fontSize: "1rem", fontWeight: "normal", maxWidth: "100%"}}>
                        <span style={{
                            maxWidth: "calc(100% - 20px)",
                            textOverflow: "ellipsis",
                            overflow: "hidden"
                        }}>{text}</span>
            {children}
        </Badge>
    )
}

export default MiniSearchApp;
