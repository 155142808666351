import React from "react";
import {useState} from "react";
import {Tabs, Tab, Button, Card, Spinner} from "react-bootstrap";
import {errorHandler, getACorpus, getApiKey, getApiUrl, getSomeConcepts} from "./Data";

function Search() {
    const [loadingConcepts, setLoadingConcepts] = useState(false)
    const [conceptsData, setConceptsData] = useState(null as any)
    const [conceptsPayload, setConceptsPayload] = useState(null as any)

    const [loadingRelations, setLoadingRelations] = useState(false)
    const [relationsData, setRelationsData] = useState(null as any)
    const [relationsPayload, setRelationsPayload] = useState(null as any)

    const [loadingEvidence, setLoadingEvidence] = useState(false)
    const [evidenceData, setEvidenceData] = useState(null as any)
    const [evidencePayload, setEvidencePayload] = useState(null as any)

    const [loadingMetadata, setLoadingMetadata] = useState(false)
    const [metadataData, setMetadataData] = useState(null as any)
    const [metadataPayload, setMetadataPayload] = useState(null as any)

    const [loadingArguments, setLoadingArguments] = useState(false)
    const [argumentsData, setArgumentsData] = useState(null as any)
    const [argumentsPayload, setArgumentsPayload] = useState(null as any)

    function searchConcepts() {
        setLoadingConcepts(true)
        setConceptsData(null)
        setConceptsPayload(null)

        // First get a corpus id.
        getACorpus()
            .then((corpus: any) => {
                // Then get the top concepts in the first corpus.
                const payload = {
                    "corpus_filter": {
                        "corpus_ids": [corpus.id]
                    },
                    "clustering_mode": "MODERATE",
                    "count": 10
                }
                setConceptsPayload(payload)
                return fetch(`${getApiUrl()}api/v1/search/concepts`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getApiKey()}`,
                    },
                    body: JSON.stringify(payload)
                })
            })
            .then(errorHandler)
            .then((data) => setConceptsData(data))
            .catch(window.alert)
            .finally(() => setLoadingConcepts(false))
    }

    function searchRelations() {
        setLoadingRelations(true)
        setRelationsData(null)
        setRelationsPayload(null)

        // First get a corpus id.
        getACorpus()
            .then((corpus: any) => {
                // Then get the top relations in the first corpus.
                const payload = {
                    "corpus_filter": {
                        "corpus_ids": [corpus.id]
                    },
                    "clustering_mode": "MODERATE",
                    "count": 10
                }
                setRelationsPayload(payload)
                return fetch(`${getApiUrl()}api/v1/search/relations`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getApiKey()}`,
                    },
                    body: JSON.stringify(payload)
                })
            })
            .then(errorHandler)
            .then((data) => setRelationsData(data))
            .catch(window.alert)
            .finally(() => setLoadingRelations(false))
    }

    function searchEvidence() {
        setLoadingEvidence(true)
        setEvidenceData(null)
        setEvidencePayload(null)

        let corpusId: string

        // First get a corpus id.
        getACorpus()
            .then((corpus: any) => {
                corpusId = corpus.id
                // Then get the top concepts in the first corpus.
                return getSomeConcepts(corpusId)
            })
            .then((concepts) => {
                // Then get evidence for the first.
                const payload = {
                    "count": 1,
                    "evidence_type": "Mentioned",
                    "corpus_filter": {
                        "corpus_ids": [corpusId],
                        "concept_filter": {
                            "filter": [concepts[0]]
                        },
                    }
                }
                setEvidencePayload(payload)
                return fetch(`${getApiUrl()}api/v1/search/evidence`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getApiKey()}`,
                    },
                    body: JSON.stringify(payload)
                })
            })
            .then(errorHandler)
            .then((data) => setEvidenceData(data))
            .catch(window.alert)
            .finally(() => setLoadingEvidence(false))
    }

    function searchMetadata() {
        setLoadingMetadata(true)
        setMetadataData(null)
        setMetadataPayload(null)

        // First get a corpus id.
        getACorpus()
            .then((corpus: any) => {
                // Then get metadata.
                const payload = {
                    "field": corpus.supported_metadata_fields[0].id,
                    "corpus_filter": {
                        "corpus_ids": [corpus.id],
                    }
                }
                setMetadataPayload(payload)
                return fetch(`${getApiUrl()}api/v1/search/metadata`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getApiKey()}`,
                    },
                    body: JSON.stringify(payload)
                })
            })
            .then(errorHandler)
            .then((data) => setMetadataData(data))
            .catch(window.alert)
            .finally(() => setLoadingMetadata(false))
    }

    function searchArguments() {
        setLoadingArguments(true)
        setArgumentsData(null)
        setArgumentsPayload(null)

        // First get a corpus id.
        getACorpus()
            .then((corpus: any) => {
                // Then get arguments.
                const payload = {
                    "corpus_filter": {
                        "corpus_ids": [corpus.id],
                    }
                }
                setArgumentsPayload(payload)
                return fetch(`${getApiUrl()}api/v1/search/arguments`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getApiKey()}`,
                    },
                    body: JSON.stringify(payload)
                })
            })
            .then(errorHandler)
            .then((data) => setArgumentsData(data))
            .catch(window.alert)
            .finally(() => setLoadingArguments(false))
    }

    return (
        <div>
            <h2>Search Examples</h2>
            <Tabs
                defaultActiveKey="search-concepts"
                id="search-tabs"
                className="mb-3 mt-4"
            >
                <Tab eventKey="search-concepts" title="Search Concepts">
                    <p>
                        POST {getApiUrl()}api/v1/search/concepts
                    </p>
                    <p>
                        Read the <a target="cora_v1_api_docs"
                                    href="https://kb.ec.ai/docs/post-api-v1-search-concepts">docs</a>.
                    </p>
                    <Button variant="outline-primary" onClick={searchConcepts}
                            disabled={loadingConcepts}>Execute</Button>
                    {!loadingConcepts || (<div className="mt-4">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>)}
                    {conceptsData === null || (
                        <Tabs
                            defaultActiveKey="results"
                            id="search-concepts-results"
                            className="mt-5"
                        >
                            <Tab eventKey="results" title="Results">
                                {conceptsData!.concepts.map((concept: any, i: number) => {
                                    return (
                                        <Card className="bg-light mt-2" style={{width: "50%"}} key={i}>
                                            <Card.Body>
                                                <div style={{display: "grid", gridTemplateColumns: "120px 1fr"}}>
                                                    <div><strong>Name:</strong></div>
                                                    <div className="ps-4">{concept.name}</div>

                                                    <div><strong>IDs:</strong></div>
                                                    <div className="ps-4">{concept.ids.join(", ")}</div>

                                                    <div><strong>Surface Forms:</strong></div>
                                                    <div className="ps-4">{concept.surface_forms.join(", ")}</div>

                                                    <div><strong>Type Names:</strong></div>
                                                    <div className="ps-4">{concept.type_names.join(", ")}</div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    );
                                })}
                            </Tab>
                            <Tab eventKey="payload" title="Payload">
                                <pre className="p-4"><code>{JSON.stringify(conceptsPayload, null, 2)}</code></pre>
                            </Tab>
                            <Tab eventKey="raw" title="Raw Results">
                                <pre className="p-4"><code>{JSON.stringify(conceptsData, null, 2)}</code></pre>
                            </Tab>
                        </Tabs>
                    )}
                </Tab>
                <Tab eventKey="search-relations" title="Search Relations">
                    <p>
                        POST {getApiUrl()}api/v1/search/relations
                    </p>
                    <p>
                        Read the <a target="cora_v1_api_docs"
                                    href="https://kb.ec.ai/docs/post-api-v1-search-relations">docs</a>.
                    </p>
                    <Button variant="outline-primary" onClick={searchRelations}
                            disabled={loadingRelations}>Execute</Button>
                    {!loadingRelations || (<div className="mt-4">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>)}
                    {relationsData === null || (
                        <Tabs
                            defaultActiveKey="results"
                            id="search-relations-results"
                            className="mt-5"
                        >
                            <Tab eventKey="results" title="Results">
                                {relationsData!.relations.map((concept: any, i: number) => {
                                    return (
                                        <Card className="bg-light mt-2" style={{width: "50%"}} key={i}>
                                            <Card.Body>
                                                <div style={{display: "grid", gridTemplateColumns: "120px 1fr"}}>
                                                    <div><strong>Name:</strong></div>
                                                    <div className="ps-4">{concept.name}</div>

                                                    <div><strong>IDs:</strong></div>
                                                    <div className="ps-4">{concept.ids.join(", ")}</div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    );
                                })}
                            </Tab>
                            <Tab eventKey="payload" title="Payload">
                                <pre className="p-4"><code>{JSON.stringify(relationsPayload, null, 2)}</code></pre>
                            </Tab>
                            <Tab eventKey="raw" title="Raw Results">
                                <pre className="p-4"><code>{JSON.stringify(relationsData, null, 2)}</code></pre>
                            </Tab>
                        </Tabs>
                    )}
                </Tab>
                <Tab eventKey="search-evidence" title="Search Evidence">
                    <p>
                        POST {getApiUrl()}api/v1/search/evidence
                    </p>
                    <p>
                        Read the <a target="cora_v1_api_docs"
                                    href="https://kb.ec.ai/docs/post-api-v1-search-evidence">docs</a>.
                    </p>
                    <Button variant="outline-primary" onClick={searchEvidence}
                            disabled={loadingEvidence}>Execute</Button>
                    {!loadingEvidence || (<div className="mt-4">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>)}
                    {evidenceData === null || (
                        <Tabs
                            defaultActiveKey="results"
                            id="search-evidence-results"
                            className="mt-5"
                        >
                            <Tab eventKey="results" title="Results">
                                {evidenceData!.evidence.map((item: any, i: number) => {
                                    return (
                                        <Card className="bg-light mt-2" style={{width: "50%"}} key={i}>
                                            <Card.Body>
                                                <div style={{display: "grid", gridTemplateColumns: "130px 1fr"}}>
                                                    <div><strong>Text:</strong></div>
                                                    <div className="ps-4"
                                                         style={{overflowWrap: "anywhere"}}>{item.text}</div>

                                                    <div><strong>ID:</strong></div>
                                                    <div className="ps-4"
                                                         style={{overflowWrap: "anywhere"}}>{item.id}</div>

                                                    <div><strong>Type:</strong></div>
                                                    <div className="ps-4"
                                                         style={{overflowWrap: "anywhere"}}>{item.evidence_type}</div>

                                                    <div><strong>Context Before:</strong></div>
                                                    <div className="ps-4"
                                                         style={{overflowWrap: "anywhere"}}>{item.context.before}</div>

                                                    <div><strong>Context After:</strong></div>
                                                    <div className="ps-4"
                                                         style={{overflowWrap: "anywhere"}}>{item.context.after}</div>

                                                    {item.instances.map((instance: any, j: number) => {
                                                        return (
                                                            <React.Fragment key={`inst.${i}.${j}`}>
                                                                <div><strong>Instance Title:</strong></div>
                                                                <div className="ps-4"
                                                                     style={{overflowWrap: "anywhere"}}>{instance.title}</div>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    );
                                })}
                            </Tab>
                            <Tab eventKey="payload" title="Payload">
                                <pre className="p-4"><code>{JSON.stringify(evidencePayload, null, 2)}</code></pre>
                            </Tab>
                            <Tab eventKey="raw" title="Raw Results">
                                <pre className="p-4"><code>{JSON.stringify(evidenceData, null, 2)}</code></pre>
                            </Tab>
                        </Tabs>
                    )}
                </Tab>
                <Tab eventKey="search-metadata" title="Search Metadata">
                    <p>
                        POST {getApiUrl()}api/v1/search/metadata
                    </p>
                    <p>
                        Read the <a target="cora_v1_api_docs"
                                    href="https://kb.ec.ai/docs/post-api-v1-search-metadata">docs</a>.
                    </p>
                    <Button variant="outline-primary" onClick={searchMetadata}
                            disabled={loadingMetadata}>Execute</Button>
                    {!loadingMetadata || (<div className="mt-4">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>)}
                    {metadataData === null || (
                        <Tabs
                            defaultActiveKey="results"
                            id="search-metadata-results"
                            className="mt-5"
                        >
                            <Tab eventKey="results" title="Results">
                                <Card className="bg-light mt-2" style={{width: "50%"}}>
                                    <Card.Body>
                                        <div style={{display: "grid", gridTemplateColumns: "130px 1fr"}}>
                                            <div><strong>Name:</strong></div>
                                            <div className="ps-4"
                                                 style={{overflowWrap: "anywhere"}}>{metadataData.name}</div>

                                            <div><strong>ID:</strong></div>
                                            <div className="ps-4"
                                                 style={{overflowWrap: "anywhere"}}>{metadataData.id}</div>

                                            <div><strong>Type:</strong></div>
                                            <div className="ps-4"
                                                 style={{overflowWrap: "anywhere"}}>{metadataData.type}</div>

                                            <div><strong>Start Date:</strong></div>
                                            <div className="ps-4"
                                                 style={{overflowWrap: "anywhere"}}>{metadataData.value.start}</div>

                                            <div><strong>End Date:</strong></div>
                                            <div className="ps-4"
                                                 style={{overflowWrap: "anywhere"}}>{metadataData.value.end}</div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Tab>
                            <Tab eventKey="payload" title="Payload">
                                <pre className="p-4"><code>{JSON.stringify(metadataPayload, null, 2)}</code></pre>
                            </Tab>
                            <Tab eventKey="raw" title="Raw Results">
                                <pre className="p-4"><code>{JSON.stringify(metadataData, null, 2)}</code></pre>
                            </Tab>
                        </Tabs>
                    )}
                </Tab>
                <Tab eventKey="search-arguments" title="Search Arguments">
                    <p>
                        POST {getApiUrl()}api/v1/search/arguments
                    </p>
                    <p>
                        Read the <a target="cora_v1_api_docs"
                                    href="https://kb.ec.ai/docs/post-api-v1-search-arguments">docs</a>.
                    </p>
                    <Button variant="outline-primary" onClick={searchArguments}
                            disabled={loadingArguments}>Execute</Button>
                    {!loadingArguments || (<div className="mt-4">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>)}
                    {argumentsData === null || (
                        <Tabs
                            defaultActiveKey="results"
                            id="search-arguments-results"
                            className="mt-5"
                        >
                            <Tab eventKey="results" title="Results">
                                {argumentsData!.argument_names.map((item: any, i: number) => {
                                    return (
                                        <Card className="bg-light mt-2" style={{width: "50%"}} key={i}>
                                            <Card.Body>
                                                <div style={{display: "grid", gridTemplateColumns: "130px 1fr"}}>
                                                    <div><strong>Name:</strong></div>
                                                    <div className="ps-4"
                                                         style={{overflowWrap: "anywhere"}}>{item.name}</div>

                                                    <div><strong>ID:</strong></div>
                                                    <div className="ps-4"
                                                         style={{overflowWrap: "anywhere"}}>{item.id}</div>

                                                    <div><strong>Type:</strong></div>
                                                    <div className="ps-4"
                                                         style={{overflowWrap: "anywhere"}}>{item.type}</div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    )
                                })}
                            </Tab>
                            <Tab eventKey="payload" title="Payload">
                                <pre className="p-4"><code>{JSON.stringify(argumentsPayload, null, 2)}</code></pre>
                            </Tab>
                            <Tab eventKey="raw" title="Raw Results">
                                <pre className="p-4"><code>{JSON.stringify(argumentsData, null, 2)}</code></pre>
                            </Tab>
                        </Tabs>
                    )}
                </Tab>
            </Tabs>
        </div>
    );
}

export default Search;