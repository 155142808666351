import React from 'react';
import './App.css';
import {useLocation, useNavigate} from "react-router-dom";
import {Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import Corpora from "./Corpora";
import Search from "./Search";
import Suggest from "./Suggest";
import License from "./License";
import MiniSearchApp from "./MiniSearchApp";

function App() {
    let location = useLocation();
    let navigate = useNavigate();

    React.useEffect(() => {
        if (location.pathname === "/") {
            navigate("/license", {replace: true})
        }
    })

    return (
        <Container className="mb-5">
            <Row>
                <Col>
                    <Navbar bg="light" expand="lg">
                        <Container>
                            <Navbar.Brand>CORA V1 API Demo</Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav activeKey={location.pathname} onSelect={(selectedKey) => navigate(selectedKey!)}
                                     className="me-auto">
                                    <Nav.Link eventKey="license">License</Nav.Link>
                                    <Nav.Link eventKey="corpora">Corpora</Nav.Link>
                                    <Nav.Link eventKey="search">Search</Nav.Link>
                                    <Nav.Link eventKey="suggest">Suggest</Nav.Link>
                                    <Nav.Link eventKey="mini-search-app">Mini Search App</Nav.Link>
                                    <a target="cora_v1_api_docs"
                                       href="https://kb.ec.ai/docs/cora-devguide-overview"
                                       className="nav-link">Docs</a>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>
                    <div style={{display: location.pathname === "/license" ? "" : "none"}}>
                        <License/>
                    </div>
                    <div style={{display: location.pathname === "/corpora" ? "" : "none"}}>
                        <Corpora/>
                    </div>
                    <div style={{display: location.pathname === "/search" ? "" : "none"}}>
                        <Search/>
                    </div>
                    <div style={{display: location.pathname === "/suggest" ? "" : "none"}}>
                        <Suggest/>
                    </div>
                    <div style={{display: location.pathname === "/mini-search-app" ? "" : "none"}}>
                        <MiniSearchApp/>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default App;
