let corpusId: string

export function errorHandler(res: Response): Promise<any> {
    if (res.ok) {
        return res.json();
    } else {
        return res.json().then(data => {
            let msg: string
            if (data.detail) {
                if (Array.isArray(data.detail)) {
                    msg = data.detail.map((entry: any) => `${entry.msg} at ${entry.loc.join(" > ")}`).join("\n")
                } else {
                    msg = data.detail
                }
            } else {
                msg = "an error occurred"
            }
            return Promise.reject(msg)
        })
    }
}

export function getACorpus(): Promise<string> {
    if (corpusId !== undefined) {
        return Promise.resolve(corpusId);
    } else {
        return fetch(`${getApiUrl()}api/v1/corpora`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getApiKey()}`,
            },
        })
            .then(errorHandler)
            .then((data) => data.corpora[0])
    }
}

let conceptsByCorpusId = {} as Record<string, any[]>

export function getSomeConcepts(corpusId: string): Promise<any[]> {
    if (conceptsByCorpusId[corpusId] !== undefined) {
        return Promise.resolve(conceptsByCorpusId[corpusId]);
    } else {
        return fetch(`${getApiUrl()}api/v1/search/concepts`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getApiKey()}`,
            },
            body: JSON.stringify({
                "corpus_filter": {
                    "corpus_ids": [corpusId]
                },
                "clustering_mode": "MODERATE",
                "count": 10
            })
        })
            .then(errorHandler)
            .then((data) => {
                // Remove type names for use in a corpus filter.
                conceptsByCorpusId[corpusId] = data.concepts.map((item: any) => {
                  const { type_names, ...concept } = item
                  return concept
                })
                return conceptsByCorpusId[corpusId]
            })
    }
}

export function getEvidence(corpusId: string, concepts: any[]): Promise<any[]> {
    return fetch(`${getApiUrl()}api/v1/search/evidence`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getApiKey()}`,
        },
        body: JSON.stringify({
            "count": 1,
            "evidence_type": "Mentioned",
            "corpus_filter": {
                "corpus_ids": [corpusId],
                "concept_filter": {
                    "filter": concepts
                },
            }
        })
    })
        .then(errorHandler)
        .then((data) => data.evidence)
}

export function getApiUrl() {
    return localStorage.getItem("apiUrl") || "https://api.cora.ec.ai/"
}

export function getApiKey() {
    return localStorage.getItem("apiKey") || ""
}
